<template>
  <section>
    <div class="header header-home">
      <app-navigation />

      <br />

      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="introduction">
              <div class="stranger">
                <img alt="" src="../assets/image/hi.png" /> <br />
                <h3>Stranger</h3>
              </div>
              <h1>I'm Adam!</h1>
              <h3>DevOps and Full Stack Engineer</h3>
              <h6>I have more than 20 years of experience managing Linux servers and writing code and I am ready to work for you!</h6>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
<!--            <div class="avatar">-->
<!--              <img src="../assets/image/avatar.png" />-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <p>I am an innovative and experienced <strong>DevOps Engineer</strong> with a strong <strong>Linux</strong> background and over <strong>15 years</strong> of experience designing, implementing, and managing cutting-edge deployments of cloud resources using Amazon Web Services, VMware, and traditional physical hardware. I am seeking a position within an organization that can apply my current skill set but also give me the opportunity to learn new skills especially in containerization.</p>

        <div class="pb-5 pt-5 text-center">
          <img alt="" src="../assets/image/uc.webp" class="text-center" />
        </div>
      </div>
    </div>
    <app-footer />
  </section>
</template>

<script>
import appNavigation from '../components/Navigation'
import appFooter from '../components/Footer.vue'

export default {
  components: {
    appNavigation,
    appFooter
  },
  head: {
    title: "DevOps Engineer, AWS Engineer, Software Developer, VueJS Developer, NodeJS Developer, PHP Developer, and more | Adam L Miller",
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'I am a seasoned veteran in AWS DevOps as well as Software Development using VueJS, NodeJS, PHP and more!'
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: 'devops engineer, aws engineer, software developer, vuejs developer, nodejs developer, php developer'
      }
    ]
  }
}
</script>
